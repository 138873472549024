form {
  align-self: center;
  vertical-align: middle;
  .ant-input,
  .ant-input-password {
    padding: 10px;
    border-radius: 2px;
    padding: 10px 20px;
    font-size: 16px;
  }
  label {
    font-weight: 500;
    font-size: 18px;
    display: block;
    margin-bottom: 10px;
  }
}
