.navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  border-top: 1px solid var(--yay-graylg-color);
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.15);
  min-height: 60px;
  padding: 5px;
  padding-bottom: env(safe-area-inset-bottom) !important;
}
