.login-view {
  height: 100vh !important;
  position: relative;
  .ant-col {
    padding: 0 50px;
  }

  .form-header {
    max-width: 600px;
    padding-right: 50px;
    display: block;
  }

  .image {
    padding: 0;
    background: url("../../../assets/img/login-img.jpg") no-repeat;
    background-size: cover;
    height: 100%;
  }

  .logo {
    width: 200px;
  }

  .hidde-mobile {
    @media screen and(max-width:800px) {
      display: none !important;
    }
  }

  h2 {
    @media screen and (max-width: 800px) {
      text-align: center;
    }
  }
}
