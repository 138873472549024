.active {
  color: var(--yay-red-color);
  fill: var(--yay-red-color);
  * {
    color: var(--yay-red-color);
    fill: var(--yay-red-color);
  }
}

.live {
  path {
    color: cyan;
    fill: cyan;
  }
}
