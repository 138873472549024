.green-card {
  background: var(--yay-primary-color);
  border: 1px solid var(--yay-border-color);
}

.starts-in {
  padding: 5px 10px;
  background-color: var(--yay-graylg-color);
}

.icon-header {
  text-align: center;
  display: block;
  line-height: 1;
  font-size: 24px;
  color: var(--yay-gray-acent-color);
}

.search {
  border-radius: 20px;
  font-size: 14px;
  padding: 7px;
  .icon {
    color: var(--yay-gray-acent-color);
    font-size: 20px;
  }
}

.ant-tabs-tab-btn {
  color: black;
}

.carousel {
  width: 100%;
  overflow: auto;
  white-space: nowrap;
}

.carousel-container {
  display: inline-flex;
}

.carousel-item {
  flex: 0 0 auto;
  width: 190px; /* Ajusta el ancho de cada elemento según tus necesidades */
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden; /* Oculta el contenido desbordado */
  text-overflow: ellipsis; /* Muestra puntos suspensivos (...) para indicar que hay más contenido */
  white-space: nowrap;
  text-align: center;
}

/* Ajusta los estilos según tus preferencias */

/* Ajustar el tamaño y estilo según tus necesidades */
