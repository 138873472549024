/*FORMAT TEXT SIZES*/
:root {
  --yay-primary-color: #cef24b;

  --yay-gray-text-color: #140d27;
  --yay-fuschia-color: #ff196c;
  --yay-graylg-color: #f2f2f2;
  --yay-gray-acent-color: #6a6a6a;
  --yay-red-color: #ff3f01;
  --yay-border-color: #ccc;
}

h1 {
  font-size: 28px;
}

h2 {
  font-size: 20px;
}
.t70 {
  font-size: 70px;
}

.t25 {
  font-size: 25px;
}

.t18 {
  font-size: 18px;
}

.t16 {
  font-size: 16px;
}

.t14 {
  font-size: 14px;
}

.t12 {
  font-size: 12px;
}

.t10 {
  font-size: 10px;
}

.bordergray {
  border: 1px solid #4f4f4f;
}

body {
  font-size: 14px;
}

.bold {
  font-weight: bold;
}

.tl {
  font-weight: 200;
}

.tc {
  text-align: center;
}

.center {
  display: table;
  margin: auto;
}

.block {
  display: block;
}

.tgray {
  color: var(--yay-gray-acent-color);
}

//Buttons
Button {
  &.fuschia {
    background: var(--yay-fuschia-color) !important;
    color: white !important;
  }
}

//curve
.curve {
  border-radius: 20px;
}
