.event-home {
  background-color: #fef6db;
  position: relative;
  display: block;
  height: fit-content;
  padding: 20px;
  border: 1px solid var(--yay-border-color);

  &.paid {
    padding-top: 40px;
  }

  .paid {
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    border-radius: 20px 20px 0 0;
    background-color: var(--yay-primary-color);
    line-height: 2;
    font-weight: bold;
  }
  span {
    overflow: hidden; /* Oculta el contenido que se desborda */
    text-overflow: ellipsis; /* Agrega puntos suspensivos al final del texto truncado */
    white-space: normal;
  }
}

.live-tag {
  margin-top: 10px;
  color: cyan !important;
  * {
    float: left;
  }
  justify-content: center;
  align-items: center;
}
